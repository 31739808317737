<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">
        Tem certeza de que deseja excluir "{{ pergunta.titulo }}"?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="closeDialog()">Fechar</v-btn>
        <v-btn color="success" @click="remove()">Excluir</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalDeletePergunta",
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$store.dispatch("pcoPerguntas/resetPergunta");
    },
    remove() {
      let indexQuestion = null;
      if (this.pergunta.subcategoria) {
        indexQuestion = this.listaPerguntas[this.pergunta.categoria][
          this.pergunta.subcategoria
        ].findIndex((el) => el.code_front == this.pergunta.code_front);

        this.listaPerguntas[this.pergunta.categoria][
          this.pergunta.subcategoria
        ].splice(indexQuestion, 1);
      } else {
        indexQuestion = this.listaPerguntas[this.pergunta.categoria].findIndex(
          (el) => el.code_front == this.pergunta.code_front
        );

        this.listaPerguntas[this.pergunta.categoria].splice(indexQuestion, 1);
      }

      this.closeDialog();
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getDialogDeletePergunta"];
      },
      set(bool) {
        this.$store.dispatch("pesquisas/setDialogDeletePergunta", bool);
      },
    },
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    listaPerguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"];
      },
      set(value) {
        this.$store.dispatch("pcoPerguntas/setPerguntas", value);
      },
    },
  },
};
</script>

<style></style>
