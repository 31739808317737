<template>
  <v-dialog v-model="dialog" scrollable>
    <v-card :loading="loadingCard">
      <div
        class="d-flex flex-row align-center justify-space-between full-width mx-3"
      >
        <v-card-title>{{ tituloModal }}</v-card-title>
        <v-btn icon elevation="0" @click="closeDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <v-divider></v-divider>
      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex flex-sm-row flex-column">
        <v-btn
          v-if="ocultarBotaoDeAcao"
          class="full-width"
          color="primary"
          large
          elevation="0"
          @click="$emit('action')"
        >
          {{ tituloBotaoSalvar }}
        </v-btn>

        <v-btn class="ml-3" large text @click="closeDialog">
          {{ textButtonCancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      require: true,
      default: false,
    },
    tituloModal: {
      require: true,
      default: "",
    },
    tituloBotaoSalvar: {
      require: false,
      default: "Salvar",
    },
    ocultarBotaoDeAcao: {
      require: false,
      default: true,
    },
    loadingCard: {
      require: false,
      default: false,
    },
    textButtonCancel: {
      require: false,
      default: "Cancelar",
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("dialog-event");
    },
  },
  computed: {},
};
</script>

<style scoped>
.p-0-h-auto {
  height: auto !important;
  padding: 0 !important;
}
</style>
