import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"loading":_vm.loadingCard}},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between full-width mx-3"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.tituloModal))]),_c(VBtn,{attrs:{"icon":"","elevation":"0"},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VCardText,[_vm._t("content")],2),_c(VDivider),_c(VCardActions,{staticClass:"d-flex flex-sm-row flex-column"},[(_vm.ocultarBotaoDeAcao)?_c(VBtn,{staticClass:"full-width",attrs:{"color":"primary","large":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._v(" "+_vm._s(_vm.tituloBotaoSalvar)+" ")]):_vm._e(),_c(VBtn,{staticClass:"ml-3",attrs:{"large":"","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.textButtonCancel)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }