<template>
  <section name="configuracaoPesquisa">
    <v-row>
      <v-col class="d-flex pb-5" cols="12">
        <v-btn
          color="primary"
          outlined
          class="mr-auto"
          :to="{ name: 'pco-pesquisas' }"
        >
          <v-icon size="18" class="me-1"> mdi-arrow-left-thin </v-icon>
          <span>voltar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="activeStep" class="custom-header">
          <!-- Header -->
          <v-stepper-header>
            <!-- Header: Step 1 -->
            <v-stepper-step :complete="activeStep > 1" step="1">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3"
                  >01</span
                >
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold"
                    >Configurações</span
                  >
                  <span class="text--secondary text-xs"
                    >Informações da pesquisa</span
                  >
                </div>
              </div>
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Header: Step 2 -->
            <v-stepper-step :complete="activeStep > 2" step="2">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3"
                  >02</span
                >
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold"
                    >Perguntas
                  </span>
                  <span class="text--secondary text-xs"
                    >Estruturação do formulário</span
                  >
                </div>
              </div>
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Header: Step 3 -->
            <v-stepper-step step="3">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3"
                  >03</span
                >
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold"
                    >Finalizar</span
                  >
                  <span class="text--secondary text-xs"
                    >Informações gerais da nova pesquisa</span
                  >
                </div>
              </div>
            </v-stepper-step>
          </v-stepper-header>

          <!-- Stepper Items -->
          <v-stepper-items>
            <!-- Stepper Content: Step 1 -->
            <v-stepper-content step="1">
              <v-card>
                <v-card-text>
                  <v-row>
                    <!-- Título -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="titulo"
                        label="Título"
                        placeholder="Ex.: Pesquisa de clima 03"
                        required
                        outlined
                        dense
                        hide-details
                        v-model="pesquisa.titulo"
                        persistent-hint
                        v-validate="'required|min:3'"
                        id="titulo"
                        name="titulo"
                        :hint="
                          errors.first('stepper1.titulo')
                            ? errors.first('stepper1.titulo')
                            : ''
                        "
                        :error="
                          errors.collect('stepper1.titulo').length
                            ? true
                            : false
                        "
                        data-vv-validate-on="change"
                        data-vv-as="título"
                        data-vv-scope="stepper1"
                      ></v-text-field>
                    </v-col>
                    <!-- Quantidade participantes -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="expectativa_de_respondentes"
                        label="Quantidade participantes"
                        placeholder="Ex.: 100"
                        required
                        outlined
                        type="number"
                        dense
                        v-model="pesquisa.expectativa_de_respondentes"
                        persistent-hint
                        hide-details
                        v-validate="'required|min_value:1|max_value:9999'"
                        id="expectativa_de_respondentes"
                        name="expectativa_de_respondentes"
                        :hint="
                          errors.first('stepper1.expectativa_de_respondentes')
                            ? errors.first(
                                'stepper1.expectativa_de_respondentes'
                              )
                            : ''
                        "
                        :error="
                          errors.collect('stepper1.expectativa_de_respondentes')
                            .length
                            ? true
                            : false
                        "
                        data-vv-validate-on="change|blur|input"
                        data-vv-as="quantidade participantes"
                        data-vv-scope="stepper1"
                      ></v-text-field>
                    </v-col>
                    <!-- Responsavel -->
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        ref="id_responsavel"
                        label="Responsável"
                        :items="responsaveis"
                        item-value="id"
                        item-text="nome"
                        dense
                        outlined
                        hide-details
                        clearable
                        clear
                        v-model="pesquisa.id_responsavel"
                        v-validate="'required'"
                        required
                        persistent-hint
                        :hint="
                          errors.first('stepper1.id_responsavel')
                            ? errors.first('stepper1.id_responsavel')
                            : ''
                        "
                        :error="
                          errors.collect('stepper1.id_responsavel').length
                            ? true
                            : false
                        "
                        data-vv-validate-on="change|blur|input"
                        data-vv-as="responsável"
                        data-vv-scope="stepper1"
                        no-data-text="Nenhum dado disponível"
                        id="id_responsavel"
                        name="id_responsavel"
                        :loading="responsaveis.length == 0 ? true : false"
                        :disabled="responsaveis.length == 0 ? true : false"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Cliente -->
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        ref="id_cliente"
                        label="Cliente"
                        :items="clientes"
                        item-value="id"
                        item-text="nome"
                        dense
                        outlined
                        hide-details
                        clearable
                        clear
                        v-model="pesquisa.id_cliente"
                        v-validate="'required'"
                        required
                        persistent-hint
                        :hint="
                          errors.first('stepper1.id_cliente')
                            ? errors.first('stepper1.id_cliente')
                            : ''
                        "
                        :error="
                          errors.collect('stepper1.id_cliente').length
                            ? true
                            : false
                        "
                        data-vv-validate-on="change|blur|input"
                        data-vv-as="cliente"
                        data-vv-scope="stepper1"
                        no-data-text="Nenhum dado disponível"
                        id="id_cliente"
                        name="id_cliente"
                        :loading="responsaveis.length == 0 ? true : false"
                        :disabled="responsaveis.length == 0 ? true : false"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Data Início -->
                    <v-col cols="12" md="6">
                      <v-menu
                        v-model="menu.data_inicio"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('stepper1.data_inicio')
                                ? errors.first('stepper1.data_inicio')
                                : ''
                            "
                            :error="
                              errors.collect('stepper1.data_inicio').length
                                ? true
                                : false
                            "
                            data-vv-validate-on="change|blur|input"
                            data-vv-as="data início"
                            data-vv-scope="stepper1"
                            v-model="dataHoraInicio"
                            id="data_inicio"
                            name="data_inicio"
                            label="Data início"
                            append-icon="mdi-calendar-today"
                            hide-details
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="pesquisa.data_inicio"
                          @input="menu.data_inicio = false"
                          :min="validDateCurrent(undefined, 'inicio')"
                          locale="pt-BR"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Hora Início -->
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu_hora_inicio"
                        v-model="menu.hora_inicio"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="pesquisa.hora_inicio"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('stepper1.hora_inicio')
                                ? errors.first('stepper1.hora_inicio')
                                : ''
                            "
                            :error="
                              errors.collect('stepper1.hora_inicio').length
                                ? true
                                : false
                            "
                            data-vv-validate-on="change|blur|input"
                            data-vv-as="hora início"
                            data-vv-scope="stepper1"
                            v-model="pesquisa.hora_inicio"
                            id="hora_inicio"
                            name="hora_inicio"
                            label="Hora início"
                            append-icon="mdi-clock-time-four-outline"
                            hide-details
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu.hora_inicio"
                          v-model="pesquisa.hora_inicio"
                          format="24hr"
                          full-width
                          @click:minute="
                            $refs.menu_hora_inicio.save(pesquisa.hora_inicio)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <!-- Data final -->
                    <v-col cols="12" md="6">
                      <v-menu
                        v-model="menu.data_final"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="!pesquisa.data_inicio"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('stepper1.data_final')
                                ? errors.first('stepper1.data_final')
                                : ''
                            "
                            :error="
                              errors.collect('stepper1.data_final').length
                                ? true
                                : false
                            "
                            data-vv-validate-on="change|blur|input"
                            data-vv-as="data final"
                            data-vv-scope="stepper1"
                            v-model="dataHoraFinal"
                            id="data_final"
                            name="data_final"
                            label="Data final"
                            append-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="pesquisa.data_final"
                          @input="menu.data_final = false"
                          :min="validDateCurrentFinish()"
                          locale="pt-BR"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Hora final -->
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu_hora_final"
                        v-model="menu.hora_final"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="pesquisa.hora_final"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('stepper1.hora_final')
                                ? errors.first('stepper1.hora_final')
                                : ''
                            "
                            :error="
                              errors.collect('stepper1.hora_final').length
                                ? true
                                : false
                            "
                            data-vv-validate-on="change|blur|input"
                            data-vv-as="hora final"
                            data-vv-scope="stepper1"
                            v-model="pesquisa.hora_final"
                            id="hora_final"
                            name="hora_final"
                            label="Hora final"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            dense
                            outlined
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu.hora_final"
                          v-model="pesquisa.hora_final"
                          format="24hr"
                          full-width
                          @click:minute="
                            $refs.menu_hora_final.save(pesquisa.hora_final)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <!-- Devolutivas -->
                    <v-col cols="12" md="12">
                      <v-autocomplete
                        ref="devolutivas"
                        label="Devolutivas"
                        :items="devolutivas"
                        item-value="id"
                        item-text="nome"
                        dense
                        outlined
                        v-model="pesquisa.devolutivas"
                        v-validate="'required'"
                        required
                        persistent-hint
                        :hint="
                          errors.first('stepper1.devolutivas')
                            ? errors.first('stepper1.devolutivas')
                            : ''
                        "
                        :error="
                          errors.collect('stepper1.devolutivas').length
                            ? true
                            : false
                        "
                        data-vv-validate-on="change"
                        data-vv-as="devolutivas"
                        data-vv-scope="stepper1"
                        no-data-text="Nenhum dado disponível"
                        id="devolutivas"
                        name="devolutivas"
                        multiple
                        hide-details
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.nome }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption"
                            >(+{{
                              pesquisa.devolutivas &&
                              pesquisa.devolutivas.length - 1
                            }}
                            outros)</span
                          >
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggleSelect('devolutivas', $event)"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  pesquisa.devolutivas &&
                                  pesquisa.devolutivas > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{
                                  iconSelect("devolutivas", "devolutiva")
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  pesquisa.devolutivas.length !=
                                  devolutivas.length
                                    ? "Selecionar todos"
                                    : "Desmarcar todos"
                                }}</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <!-- Descrição -->
                    <v-col cols="12" md="12">
                      <label for="descricao" class="mb-1"
                        >Texto de apresentação</label
                      >
                      <vue-editor
                        v-model="pesquisa.descricao"
                        :editorToolbar="customToolbar"
                      ></vue-editor>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <!-- Anonimato -->
                        <v-col cols="12" sm="12" md="12">
                          <p class="text-size-130">Pesquisa Anônima?</p>
                          <v-radio-group v-model="pesquisa.anonimo" row>
                            <v-radio label="Sim" value="S"></v-radio>
                            <v-radio label="Não" value="N"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <!-- Lista de e-mails -->
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="pesquisa.anonimo == 'N'"
                        >
                          <p class="text-size-130">
                            Selecione o arquivo com e-mails dos participantes
                          </p>
                          <v-file-input
                            append-icon="mdi-paperclip"
                            clearable
                            prepend-icon=""
                            v-model="pesquisa.arquivo"
                            ref="file-input"
                            class="mb-2"
                            placeholder="Nenhum Arquivo Selecionado"
                            id="arquivo"
                            name="arquivo"
                            label="Arquivo"
                            outlined
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          >
                          </v-file-input>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <v-btn color="primary" @click="validateStep(activeStep)">
                    Próximo
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!-- Stepper Content: Step 2 -->
            <v-stepper-content step="2">
              <!-- Escolha do modelo -->
              <v-row>
                <v-col cols="12">
                  <v-card elevation="0">
                    <v-card-title
                      tag="h3"
                      class="text-no-wrap font-weight-medium text-2xl pt-4"
                    >
                      Escolha do modelo
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <!-- Tipo -->
                        <v-col cols="12" md="3">
                          <v-select
                            label="Tipo"
                            :items="itemsTipo"
                            item-value="id"
                            item-text="nome"
                            dense
                            outlined
                            v-model="pesquisa.tipo"
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('filtrar.tipo')
                                ? errors.first('filtrar.tipo')
                                : ''
                            "
                            :error="
                              errors.collect('filtrar.tipo').length
                                ? true
                                : false
                            "
                            hide-details
                            data-vv-validate-on="change|blur|input"
                            data-vv-as="tipo"
                            data-vv-scope="filtrar"
                            no-data-text="Nenhum dado disponível"
                            id="tipo"
                            name="tipo"
                            @change="resolvedTypeModel(pesquisa.tipo)"
                          ></v-select>
                        </v-col>
                        <!-- Modelo -->
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            label="Modelo"
                            :items="modelos"
                            item-value="id"
                            item-text="nome"
                            dense
                            outlined
                            v-model="pesquisa.id_modelo"
                            v-validate="'required'"
                            required
                            persistent-hint
                            :hint="
                              errors.first('filtrar.modelo')
                                ? errors.first('filtrar.modelo')
                                : ''
                            "
                            :error="
                              errors.collect('filtrar.modelo').length
                                ? true
                                : false
                            "
                            hide-details
                            data-vv-validate-on="change"
                            data-vv-as="cliente"
                            data-vv-scope="filtrar"
                            no-data-text="Nenhum dado disponível"
                            id="modelo"
                            name="modelo"
                            :loading="loadings.loadingModelo"
                            :disabled="disabledModelos"
                          ></v-autocomplete>
                        </v-col>
                        <!-- Categorias -->
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            label="Categorias"
                            :items="itemsCategorias"
                            item-value="id"
                            item-text="nome"
                            dense
                            outlined
                            v-model="categorias"
                            v-validate="'required'"
                            required
                            persistent-hint
                            multiple
                            :hint="
                              errors.first('filtrar.categorias')
                                ? errors.first('filtrar.categorias')
                                : ''
                            "
                            :error="
                              errors.collect('filtrar.categorias').length
                                ? true
                                : false
                            "
                            hide-details
                            data-vv-validate-on="change"
                            data-vv-as="categorias"
                            data-vv-scope="filtrar"
                            no-data-text="Nenhum dado disponível"
                            id="categorias"
                            name="categorias"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index === 0">
                                <span>{{ item.nome }}</span>
                              </v-chip>
                              <span
                                v-if="index === 1"
                                class="grey--text caption"
                                >(+{{
                                  categorias && categorias.length - 1
                                }}
                                outros)</span
                              >
                            </template>

                            <template v-slot:prepend-item>
                              <v-list-item
                                ripple
                                @click="
                                  toggleSelectCategorias('categorias', $event)
                                "
                              >
                                <v-list-item-action>
                                  <v-icon
                                    :color="
                                      categorias && categorias.length > 0
                                        ? 'indigo darken-4'
                                        : ''
                                    "
                                    >{{
                                      iconSelectCategorias(
                                        "categorias",
                                        "categoria"
                                      )
                                    }}</v-icon
                                  >
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      categorias.length !=
                                      itemsCategorias.length
                                        ? "Selecionar todos"
                                        : "Desmarcar todos"
                                    }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <!-- Botão de ação -->
                        <v-col cols="12" md="2">
                          <v-btn
                            :loading="loadings.loadingPerguntas"
                            :disabled="loadings.loadingPerguntas"
                            color="primary"
                            class="ml-5 white--text"
                            @click="buscarPerguntas()"
                          >
                            Filtrar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Lista de perguntas -->
              <v-row>
                <v-col cols="12">
                  <v-card elevation="0">
                    <v-card-title
                      tag="h3"
                      class="text-no-wrap font-weight-medium text-2xl pt-4"
                    >
                      Lista de Perguntas
                    </v-card-title>
                    <!-- actions -->
                    <v-card-text
                      class="d-flex align-center justify-end flex-wrap pb-0"
                    >
                      <div
                        class="d-flex flex-row align-center justify-end flex-sm-nowrap flex-wrap mb-4"
                      >
                        <v-select
                          outlined
                          v-model="modalPerguntaSelecionada"
                          :items="itemsCategorias"
                          item-text="nome"
                          item-value="id"
                          label="Qual tipo de pergunta deseja cadastrar?"
                          hide-details
                          dense
                          class="mx-1 my-1"
                        ></v-select>
                        <v-btn
                          color="primary"
                          @click="openModalPergunta()"
                          class="mx-1 my-1"
                        >
                          <span>Nova pergunta</span>
                        </v-btn>
                        <v-btn color="secondary" outlined class="mx-1 my-1">
                          <v-icon size="17" class="me-1"> </v-icon>
                          <span>Exportar</span>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card elevation="0">
                    <v-card-text>
                      <v-tabs
                        v-model="tabSelected[0]"
                        center-active
                        show-arrows
                      >
                        <v-tab
                          v-for="(tab, index) in tabsCategorias"
                          :disabled="tab.disabled"
                          :key="index"
                          :href="`#tab-${index}`"
                          style="color: #5c6da7"
                        >
                          <v-badge
                            :content="getLengthQuestions(tab.scopeValidation)"
                          >
                            {{ tab.name }}
                          </v-badge>
                        </v-tab>
                        <v-tab-item
                          v-for="(tab, index) in tabsCategorias"
                          :key="index"
                          :value="'tab-' + index"
                        >
                          <v-card
                            flat
                            tile
                            style="background-color: white !important"
                          >
                            <component
                              :ref="tab.ref"
                              :is="tab.component"
                            ></component>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Bottões -->
              <v-row>
                <v-col cols="12">
                  <div
                    class="d-flex flex-row justify-space-between align-center"
                  >
                    <v-btn text @click="activeStep = 1"> Voltar </v-btn>
                    <v-btn color="primary" @click="validateStep(activeStep)">
                      Próximo
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Stepper Content: Step 3 -->
            <v-stepper-content step="3">
              <v-card class="mb-8">
                <v-col cols="12" md="12">
                  <v-card outlined class="mb-1">
                    <v-card-title> Informações da pesquisa </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h4 class="mb-1">
                            <span class="font-weight-medium me-1"
                              >Responsável</span
                            >
                          </h4>
                          <p>
                            {{
                              resolvedNameResponsible(pesquisa.id_responsavel)
                            }}
                          </p>

                          <h4 class="font-weight-medium mb-1">Cliente</h4>
                          <p>Razão social do cliente</p>

                          <h4 class="font-weight-medium me-2 mb-1">
                            Descrição
                          </h4>
                          <p>{{ pesquisa.titulo }}</p>

                          <h4 class="font-weight-medium mb-1">Devolutivas</h4>
                          <p>
                            <v-chip
                              class="ma-2"
                              color="primary"
                              label
                              outlined
                              v-for="item in pesquisa.devolutivas"
                              :key="item"
                            >
                              <v-icon left> mdi-label </v-icon>
                              {{ item }}
                            </v-chip>
                          </p>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-alert color="warning" text>
                            <p class="font-weight-semibold mb-1">
                              Datas e horas
                            </p>
                          </v-alert>
                          <p class="mt-2">
                            Início: {{ pesquisa.data_inicio | dateFormatBr() }}
                            {{ pesquisa.hora_inicio }}h
                          </p>
                          <p class="mt-2">
                            Final: {{ pesquisa.data_final | dateFormatBr() }}
                            {{ pesquisa.hora_final }}h
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="12">
                  <v-card outlined class="mb-7">
                    <v-card-title> Estrutura do formulário</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h4 class="font-weight-medium mb-1">Modelo</h4>
                          <p v-if="returnModeloSelectedInPesquisa">
                            {{ returnModeloSelectedInPesquisa.nome }}
                          </p>
                          <p v-else>Nenhum modelo selecionado</p>

                          <h4 class="mb-1">
                            <span class="font-weight-medium me-1"
                              >Tipo do modelo</span
                            >
                          </h4>
                          <p v-if="returnModeloSelectedInPesquisa">
                            {{
                              returnModeloSelectedInPesquisa.padrao
                                | capitalize()
                            }}
                          </p>
                          <p v-else>Nenhum modelo selecionado</p>

                          <div class="d-flex align-center">
                            <h4 class="font-weight-medium me-2 mb-1">
                              Categorias
                            </h4>
                          </div>
                          <p v-if="categoriesSelected != undefined">
                            <v-chip
                              class="ma-2"
                              color="primary"
                              label
                              outlined
                              v-for="item in categoriesSelected"
                              :key="item"
                            >
                              <v-icon left> mdi-label </v-icon>
                              {{ item }}
                            </v-chip>
                          </p>
                        </v-col>

                        <!-- <v-col cols="12" md="6">
                          <v-alert color="warning" text>
                            <p class="font-weight-semibold mb-1">
                              Visualizar formulário
                            </p>
                            <p class="text-sm mb-0">link</p>
                          </v-alert>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-card>

              <div class="d-flex justify-space-between">
                <v-btn outlined @click="activeStep = 2"> Voltar </v-btn>
                <v-btn
                  color="primary"
                  @click="validateStep(activeStep)"
                  :loading="loadings.loadingBotao"
                >
                  Salvar
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <ModalDeletePergunta />
    <v-overlay :value="loadings.loadingCentral">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>

    <!-- Forms -->
    <component :is="stepsQuestions.form"></component>
  </section>
</template>

<script>
import ModalComponent from "../components/ModalComponent.vue";
import logo from "@/assets/images/logos/legiio.png";
import legiioBanner from "@/assets/images/misc/legiio-banner-1.jpg";
import personalizadoBanner from "@/assets/images/misc/personalizado-banner.png";
import moment from "moment";
import draggable from "vuedraggable";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { getLaterDate, setDayLaterDate } from "@/helpers";
import ModalDeletePergunta from "../components/ModalDeletePergunta.vue";
import Vue from "vue";

export default {
  name: "PcoConfiguracoesPesquisa",
  inject: ["$validator"],
  components: {
    ModalComponent,
    draggable,
    PerfectScrollbar,
    ModalDeletePergunta,
  },
  props: {
    action: {
      type: String,
      default: "",
      required: true,
      validator(value) {
        if (!value) {
          return null;
        }

        return typeof value == "string" ? value : null;
      },
    },
    uuid: {
      type: String,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      activeStep: 1,
      logotipo: logo,
      legiioBanner: legiioBanner,
      personalizadoBanner: personalizadoBanner,
      loadings: {
        loadingCentral: false,
        loadingModelo: false,
        loadingBotao: false,
        loadingPerguntas: false,
      },
      disabledModelos: true,
      step: 1,
      menu: {
        data_inicio: null,
        data_final: null,
        data_final: null,
        hora_final: null,
        data_inicio_min: null,
        data_final_min: null,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      itemsTipo: [
        { id: "legiio", nome: "Modelos Legiio" },
        { id: "personalizado", nome: "Modelos personalizados" },
      ],
      itemsCategorias: [
        { id: "FE", nome: "Fatores externos" },
        { id: "FAV", nome: "Favorabilidade" },
        { id: "BEN", nome: "Benefícios" },
        { id: "MOT", nome: "Motivacional" },
        { id: "DES", nome: "Desmotivacional" },
      ],
      perguntas: [],
      edicao: false,
      categorias: [],
    };
  },

  /**
   * @description Bloqueia o usuário caso esteja tentando acessar a página de forma inesperada.
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  beforeRouteEnter(to, from, next) {
    if (to.params.action == "criar" && !to.params.uuid) {
      return next();
    }

    if (to.params.action == "editar" && to.params.uuid) {
      return next();
    }

    return next(from.path);
  },

  async created() {
    this.$store.dispatch("pcoPerguntas/resetPerguntas");

    this.pesquisa = {
      titulo: "",
      descricao: "",
      id_cliente: null,
      id_modelo: null,
      data_inicio: "",
      hora_inicio: "",
      data_final: "",
      hora_final: "",
      status: "A",
      anonimo: "",
      arquivo: "",
      tipo: null,
      devolutivas: [],
    };

    if (this.uuid) {
      this.loadings.loadingCentral = true;

      await this.$store.dispatch("pesquisas/fetchAllData", this.uuid);

      this.loadings.loadingCentral = false;
    }

    this.$store.dispatch("pesquisas/setLoading");

    this.$store.dispatch("usuarios/buscarGestores");
    this.$store.dispatch("clientes/retornaClientes");

    this.$store.dispatch("pesquisas/setLoading");
  },

  methods: {
    salvar() {
      this.loadings.loadingBotao = true;
      const isEdition = this.action == "editar" ? true : false;
      const url = isEdition ? `pesquisas/${this.uuid}` : "pesquisas";
      const _method = isEdition ? "PUT" : "POST";

      Vue.set(this.pesquisa, "is_edit", isEdition);
      Vue.set(this.pesquisa, "url", url);
      Vue.set(this.pesquisa, "_method", _method);

      this.$store
        .dispatch("pesquisas/save", this.pesquisa)
        .then((data) => {
          if (data != "NAO_AUTORIZADO") {
            if (isEdition) {
              const index = this.pesquisas.findIndex(
                (el) => el.id == this.pesquisa.id
              );

              this.pesquisas[index] = this.pesquisa;
            } else {
              this.pesquisas.push(data);
            }

            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "success",
              timeout: 3000,
              text: "Pesquisa salva com sucesso.",
            });

            this.$router.push({ name: "pco-pesquisas" });
          }
        })
        .catch(() => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Houve um erro ao editar pesquisa.",
          });
        })
        .finally(() => {
          this.loadings.loadingBotao = false;
          this.$store.dispatch("pcoPerguntas/resetPerguntas");
          this.pesquisa = {
            titulo: "",
            descricao: "",
            id_cliente: null,
            id_responsavel: null,
            id_modelo: null,
            data_inicio: "",
            hora_inicio: "",
            data_final: "",
            hora_final: "",
            status: "A",
            anonimo: "",
            arquivo: "",
          };
        });
    },
    savePergunta() {
      this.setPositionInQuestion();
      this.$store.dispatch("pesquisas/setAtualizarTabs", this.pergunta);
      this.$store.dispatch("pcoPerguntas/resetPergunta");
      this.dialogModalPergunta = false;
      this.loadingsModelo.loadingModalPergunta = false;
    },
    /**
     * @description Seta posição para a pergunta criada na pesquisa.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    setPositionInQuestion() {
      const position =
        this.pergunta.categoria == "FE"
          ? this.listaPerguntas[this.pergunta.categoria].length + 1
          : this.listaPerguntas[this.pergunta.categoria][
              this.pergunta.subcategoria
            ].length + 1;

      this.pergunta.posicao = position;

      return this;
    },
    resolvedTypeModel() {
      this.loadings.loadingModelo = true;
      this.pesquisa.modelo = null;

      this.$store
        .dispatch("pcoModelos/buscarModelosPorTipo", {
          padrao: this.pesquisa.tipo,
        })
        .finally(() => {
          this.disabledModelos = false;
          this.loadings.loadingModelo = false;
        });
    },
    validateStep(stepper) {
      switch (stepper) {
        case 1:
          this.$validator.validate("stepper1.*").then((result) => {
            result ? (this.activeStep = 2) : false;
          });
          break;
        case 2:
          if (this.listaPerguntas && this.listaPerguntas.length === 0) {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Para finalizar o cadastro da pesquisa é necessário escolher um modelo ou cadastrar perguntas.",
            });
            return false;
          } else {
            this.activeStep = 3;
          }
          break;
        case 3:
          this.salvar();
          break;
      }
    },
    buscarPerguntas() {
      this.$validator.validate("filtrar.*").then((result) => {
        if (!result) return;

        this.loadings.loadingModelo = true;
        this.loadings.loadingPerguntas = true;
        const data = {
          id_modelo: this.pesquisa.id_modelo,
          categorias: this.categorias,
        };
        this.$store
          .dispatch("pesquisas/buscarPerguntasPorModelo", data)
          .finally(() => {
            this.loadings.loadingModelo = false;
            this.loadings.loadingPerguntas = false;
          });
      });
    },
    iconSelect(tipo, item) {
      if (this.devolutivas && this.pesquisa.devolutivas) {
        if (this.devolutivas.length == this.pesquisa.devolutivas.length)
          return "mdi-close-box";
        if (this.pesquisa.devolutivas.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggleSelect(tipo) {
      this.devolutivas.length == this.pesquisa.devolutivas.length
        ? (this.pesquisa.devolutivas = [])
        : (this.pesquisa.devolutivas = this.devolutivas.map((t) => t.id));
    },
    openModalPergunta() {
      let step = 1;

      switch (this.modalPerguntaSelecionada) {
        case "BEN":
          step = 4;
          break;
        case "DES":
          step = 5;
          break;
        case "MOT":
          step = 6;
          break;
      }

      this.$store.dispatch("pcoModelos/setStep", step);

      this.dialogModalPergunta = true;
    },
    iconSelectCategorias(tipo, item) {
      if (this.itemsCategorias && this.categorias) {
        if (this.itemsCategorias.length == this.categorias.length)
          return "mdi-close-box";
        if (this.categorias.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggleSelectCategorias(tipo) {
      this.itemsCategorias.length == this.categorias.length
        ? (this.categorias = [])
        : (this.categorias = this.itemsCategorias.map((t) => t.id));
    },
    resolvedNameResponsible(id) {
      let nome = "";
      this.responsaveis.map((el) => {
        nome = id == el.id ? el.nome : "";
      });
      return nome;
    },
    validDateCurrent() {
      return getLaterDate(3);
    },
    validDateCurrentFinish() {
      return setDayLaterDate(this.pesquisa.data_inicio, 1);
    },
    /**
     * @description Pega o tamanho da lista de perguntas que estiver citada no scopeValidation
     *
     * @param scopeValidation
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    getLengthQuestions(scopeValidation) {
      let quantityOfQuestions = 0;

      if (this.listaPerguntas) {
        switch (scopeValidation) {
          case "FE":
            quantityOfQuestions =
              this.listaPerguntas &&
              this.listaPerguntas != undefined &&
              this.listaPerguntas !== null
                ? this.listaPerguntas["FE"].length
                : 0;
            break;
          case "FAV":
            quantityOfQuestions =
              this.listaPerguntas &&
              this.listaPerguntas != undefined &&
              this.listaPerguntas !== null
                ? this.listaPerguntas["FI"]["FAV"].length
                : 0;
            break;
          case "BEN":
            quantityOfQuestions =
              this.listaPerguntas &&
              this.listaPerguntas != undefined &&
              this.listaPerguntas !== null
                ? this.listaPerguntas["FI"]["BEN"].length
                : 0;
            break;
          case "MOT":
            quantityOfQuestions =
              this.listaPerguntas &&
              this.listaPerguntas != undefined &&
              this.listaPerguntas !== null
                ? this.listaPerguntas["FI"]["MOT"].length
                : 0;
            break;
          case "DES":
            quantityOfQuestions =
              this.listaPerguntas &&
              this.listaPerguntas != undefined &&
              this.listaPerguntas !== null
                ? this.listaPerguntas["FI"]["DES"].length
                : 0;
            break;
        }
      }

      return quantityOfQuestions;
    },
  },
  computed: {
    modalPerguntaSelecionada: {
      get() {
        return this.$store.getters["pesquisas/getModalPerguntaSelecionada"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setModalPerguntaSelecionada", value);
      },
    },
    pesquisa: {
      get() {
        return this.$store.getters["pesquisas/getPesquisa"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setPesquisa", value);
      },
    },
    pesquisas: {
      get() {
        return this.$store.getters["pesquisas/getPesquisas"];
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
    clientes: {
      get() {
        return this.$store.getters["clientes/getClientes"];
      },
    },
    modelosLegiio: {
      get() {
        return this.$store.getters["modelos/getModeloLegiio"];
      },
    },
    modelosPersonalizados: {
      get() {
        return this.$store.getters["modelos/getModeloPersonalizado"];
      },
    },
    modelos: {
      get() {
        return this.$store.getters["pcoModelos/getModelos"];
      },
    },
    listaPerguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"];
      },
      set(value) {
        this.$store.dispatch("pcoPerguntas/setPerguntas", value);
      },
    },
    loading: {
      get() {
        return this.$store.getters["pesquisas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    loadingClientes: {
      get() {
        return this.$store.getters["clientes/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    loadingModelos: {
      get() {
        return this.$store.getters["modelos/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    loadingGestores: {
      get() {
        return this.$store.getters["usuarios/getLoadingGestores"];
      },
      set(value) {
        return value;
      },
    },
    tabsCategorias: {
      get() {
        return this.$store.getters["pesquisas/getTabsCategorias"];
      },
    },
    tabSelected: {
      get() {
        return this.$store.getters["pesquisas/getTab"];
      },
    },
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getDialogModalCadastroPerguntas"];
      },
      set(value) {
        this.$store.dispatch(
          "pesquisas/setDialogModalCadastroPerguntas",
          value
        );
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    clickedOnSave: {
      get() {
        return this.$store.getters["pcoModelos/getClickedOnSave"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setClickedOnSave", value);
      },
    },
    user: {
      get() {
        return JSON.parse(this.$store.getters["auth/getUsuario"]);
      },
    },
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    loadingsModelo: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
    devolutivas: {
      get() {
        return this.$store.getters["pesquisas/getDevolutivas"];
      },
    },
    dataInicio() {
      let date = moment(this.pesquisa.data_inicio);
      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }
      return null;
    },
    dataFinal() {
      let date = moment(this.pesquisa.data_final);
      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }
      return null;
    },
    dataHoraInicio() {
      let date = moment(this.pesquisa.data_inicio);
      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }
      return null;
    },
    dataHoraFinal() {
      let date = moment(this.pesquisa.data_final);
      if (date.isValid()) {
        return date.format("DD/MM/YYYY");
      }
      return null;
    },
    responsavelSelecionado() {
      const idResponsavel = this.pesquisa.id_responsavel;

      if (idResponsavel == null || idResponsavel == undefined) {
        return "Sem responsável selecionado";
      }

      const responsavel = this.responsaveis.find(
        (el) => el.id == idResponsavel
      );

      return responsavel;
    },
    modeloSelecionado() {
      const idModelo = this.pesquisa.id_modelo;

      if (idModelo == null || idModelo == undefined) {
        return "Sem padrão selecionado";
      }

      let modelo = this.modelosLegiio.find((el) => el.id == idModelo);

      if (modelo == undefined) {
        modelo = this.modelosPersonalizados.find((el) => el.id == idModelo);
      }

      return modelo;
    },
    dragOpcoes() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost",
      };
    },
    stepsQuestions() {
      if (!this.modalPerguntaSelecionada) return null;

      let item = "";
      const stepsQuestions = this.$store.getters["pcoModelos/getSteps"];
      item = stepsQuestions.find((el) =>
        (this.modalPerguntaSelecionada == "FE" && el.type == "FE") ||
        el.subcategory == this.modalPerguntaSelecionada
          ? el
          : null
      );

      // Verificar se a subcategoria é favorabilidade(FAV) se for dá um dispatch no step
      if (item.subcategory === "FAV")
        this.$store.dispatch("pcoModelos/setStep", 3);

      return item;
    },
    categoriesSelected() {
      let arrayCategorySelected = [];

      if (
        this.listaPerguntas &&
        this.listaPerguntas != null &&
        this.listaPerguntas.FE.length > 0
      )
        arrayCategorySelected.push("Fatores externos");
      if (
        this.listaPerguntas &&
        this.listaPerguntas != null &&
        this.listaPerguntas.FI.BEN.length > 0
      )
        arrayCategorySelected.push("Benefícios");
      if (
        this.listaPerguntas &&
        this.listaPerguntas != null &&
        this.listaPerguntas.FI.DES.length > 0
      )
        arrayCategorySelected.push("Desmotivacional");
      if (
        this.listaPerguntas &&
        this.listaPerguntas != null &&
        this.listaPerguntas.FI.MOT.length > 0
      )
        arrayCategorySelected.push("Motivacional");
      if (
        this.listaPerguntas &&
        this.listaPerguntas != null &&
        this.listaPerguntas.FI.FAV.length > 0
      )
        arrayCategorySelected.push("Favorabilidade");

      return arrayCategorySelected;
    },
    returnModeloSelectedInPesquisa() {
      if (this.pesquisa && this.pesquisa.modelo && this.pesquisa.modelo.nome)
        return this.pesquisa.modelo;

      if (this.pesquisa && !this.pesquisa.id_modelo) return null;

      const modeloSelected = this.modelos.find(
        (el) => el.id == this.pesquisa.id_modelo
      );

      return modeloSelected;
    },
  },
  watch: {
    clickedOnSave(newValue, oldValue) {
      if (newValue === true) {
        this.savePergunta();

        this.clickedOnSave = oldValue;
      }
    },
  },
};
</script>

<style lang="scss"></style>
